import React from 'react'
import moment from 'moment'
import groupBy from 'lodash.groupby'

const COL_SIZE = "col-sm-6 col-md-4 col-lg-3 d-flex"

const isPresent = (value) => typeof value !== 'undefined' && value !== null
const formatRainAmount = (rainAmount) => (!isPresent(rainAmount) ? '-' : rainAmount.toFixed(1))

const Card = ({children, background=null, light=null, onClick, cardStyle}) => {
  const bg = background === null ? '' : `bg-${background}`
  const textColor = light ?  'text-light' : ''
  return (
    <div className={`card mb-3 flex-grow-1 shadow ${bg} ${textColor}`} onClick={onClick} style={cardStyle}>
      <div className="card-body">
        {children}
      </div>
    </div>
  )
}

const StatusCard = ({children, status, hideGreen=false, ...props}) => (
  <Card background={(hideGreen && status === 'GREEN') ? null : statusToColor(status)}
    {...props}
  >
    {children}
  </Card>
)

const statusToColor = (status) => {
  if(status === "RED") {
    return "danger"
  } else if(status === "YELLOW") {
    return "warning"
  } else if(status === "GREEN") {
    return "success"
  } else if(status === 'OFFLINE' || status === 'ERROR') {
    return 'gray'
  } else {
    return null
  }
}

const stationPlural = (count) => (count > 1 ? 'pluviômetros' : 'pluviômetro')

const alertToStr = (status) => {
  if(status === 'GREEN') { return "Sem alertas" }
  if(status === 'YELLOW') { return "Alerta amarelo" }
  if(status === 'RED') { return "Alerta vermelho" }
  return "-"
}

const AlertByColor = ({stations}) => {
  const g = groupBy(stations, (station) => (station.alert_status) )
  console.log(g)
  return (
    <p className="mb-3">
      {Object.keys(g).map(k => {
        if(k === 'RED') {
          return (
            <span key={k}>{g[k].length} {stationPlural(g[k].length)} em alerta vermelho.</span>
          )
        } else if(k === 'YELLOW') {
          return (
            <span key={k}>{g[k].length} {stationPlural(g[k].length)} em alerta amarelo.</span>
          )
        } else {
          return (
            <span key={k}>{g[k].length} {stationPlural(g[k].length)} sem alertas.</span>
          )
        }
      })}
    </p >
  )
}

const StatusCircle = ({status}) => (<i className={`fas fa-circle text-${statusToColor(status)}`}></i>)

const StationDetails = ({station}) => (
  <div id={`station-details-${station.id}`} className="mb-4">
    <h2 className="heading-title mb-2">{station.station_name}</h2>
    <ul className="list-unstyled">
      {
      station.current_weather.error ?
        <li><StatusCircle status={'RED'} /> Falha de comunicação (erro no sistema de alertas)</li>
      : station.online ?
        <li><StatusCircle status={'GREEN'} /> Pluviômetro operando normalmente</li>
      : <li><StatusCircle status={'RED'} /> Pluviômetro pode estar offline</li>
      }

      <li><StatusCircle status={station.alert_status}/> {alertToStr(station.alert_status)}</li>
    </ul>

    <div className="row">
      <div className={COL_SIZE}>
        <StatusCard status={station.alert_status} hideGreen>
          <h3 className="">
            {formatRainAmount(station.current_weather.rain_amount_hour_mm)} mm
          </h3>
          <p className="lead">Chuva última hora</p>
        </StatusCard>
      </div>
      <div className={COL_SIZE}>
        <StatusCard status={station.alert_status} hideGreen>
          <h3 className="">
            {formatRainAmount(station.current_weather.rain_amount_24_hours_mm)} mm
          </h3>
          <p className="lead">Chuva últimas 24 horas</p>
        </StatusCard>
      </div>
    </div>
  </div>
)

const StationsPanel = ({stations}) => (
  <div className="container-fluid">
    <h1 className="my-3 heading-title">Situação geral</h1>
    <div className="row align-items-stretch mb-5">
      {stations.map( station => (
        <div className={COL_SIZE} key={station.id}>
          <StatusCard key={station.id} status={
            !station.online ? 'OFFLINE' : 
              station.current_weather.error ? 'ERROR' : station.alert_status
            } 
            onClick={() => location.href = `#station-details-${station.id}` }
            cardStyle={{cursor: 'pointer'}}
          >
            <h2>{station.station_name}</h2>
            <p className="lead">{alertToStr(station.alert_status)}</p>
            {station.current_weather.observation_time ? 
              <p className="small">Atualizado em {moment(station.current_weather.observation_time).format('lll')}</p>
              :
              <p className="small">Atualizado em --</p>
            }
            {!station.online &&
              <p className="small"><StatusCircle status={'RED'} /> Pluviômetro pode estar offline</p>
            }
          </StatusCard>
        </div>
      ))}
    </div>
    
    
    {stations.map(station => (
      <StationDetails station={station} key={station.id} />
    ))}
  </div>
)

export default StationsPanel