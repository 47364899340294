
$(document).on('click', '#sidebarCollapse', function () {
  $('#sidebar, #content').toggleClass('active');
});

$(document).on('turbolinks:load', () => {

  const ClassName = {
    ACTIVE              : 'active',
    OPEN                : 'open',
  }

  const Selector = {
    NAV_DROPDOWN_ITEMS   : '.nav-dropdown-items',
    NAV_LINK             : '.nav-link',
    NAVIGATION           : '.sidebar-nav',
  }

  const setActiveLink = () => {
    $(Selector.NAVIGATION).find(Selector.NAV_LINK).each((key, value) => {
      let link = value
      let cUrl
      cUrl = String(window.location).split('?')[0]

      if (cUrl.substr(cUrl.length - 1) === '#') {
        cUrl = cUrl.slice(0, -1)
      }
      if ($($(link))[0].href === cUrl) {
        $(link).parents('li').addClass(ClassName.ACTIVE).parents(Selector.NAV_DROPDOWN_ITEMS).add(link).each((key, value) => {
          link = value
          $(link).parent().addClass(ClassName.OPEN)
        })
      }
    })
  }
  setActiveLink()
})