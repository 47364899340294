import React from 'react'
import StationsPanel from './StationsPanel'

const Loading = () => (
  <div className="container vh-100 d-flex align-items-center justify-content-center">
    <h1><i className="fas fa-spinner fa-spin"></i></h1>
  </div>
)

const Dashboard = ({stations, error, loading}) => {
  if(error) {
    return (<div className="alert alert-danger">Erro ao carregar dados dos pluviômetros.</div>)
  }  

  if(loading && stations.length === 0) {
    return (<Loading />)
  }

  return (<StationsPanel stations={stations}/>)
  
}

export default Dashboard