import IMask from 'imask'

$(document).on('turbolinks:load', () => {
  console.log('masking inputs')

  const notificationChannelDDD = 'notification_channel_ddd'
  if(document.getElementById(notificationChannelDDD)) {
    var dddMask = IMask(
      document.getElementById(notificationChannelDDD), {
        mask: '00'
      }
    );
  }

  const notificationChannelPhoneMask = 'notification_channel_phone_mask'
  const notificationChannelPhone = 'notification_channel_phone'
  if(document.getElementById(notificationChannelPhoneMask)) {
    var phoneMask = IMask(
      document.getElementById(notificationChannelPhoneMask), {
        mask: '0 0000 0000',
        lazy: false,  // make placeholder always visible
      }
    );
  }

  $('#notification_channel_form').on('submit', () => {
    if(phoneMask) {
      console.log("setting hidden phone to ", phoneMask.unmaskedValue)
      $(`#${notificationChannelPhone}`).val(phoneMask.unmaskedValue)
    }
  })
})