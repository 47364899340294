import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from 'axios'

import Dashboard from './Dashboard'

const POOL_TIME = 30*1000
const RETRY_TIME = 5*1000

const RELOAD_TIME = 5*60*1000

const DashboardContainer = () => {
  const [stations, setStations] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  useEffect(() => {
    let ignore = false
    let timer
    setLoading(true)
    const fetchData = () => {
      axios.get('/stations').then((response) => {
        if(!ignore) {
          setStations(response.data)
          setError(null)
          timer = setTimeout(fetchData, POOL_TIME)
        }
      }).catch(err => {
        setError(err)
        timer = setTimeout(fetchData, RETRY_TIME)
      }).finally(() => {
        setLoading(false)
      })
    }
    fetchData()

    return () => {
      if(timer) { clearTimeout(timer) }
      ignore = true
    }
  }, [])


  useEffect(() => {
    const timer = setTimeout(() => (window.location.reload()), RELOAD_TIME)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Dashboard stations={stations} error={error} loading={loading} />
  )
}

export default DashboardContainer
